<template>
      
  <div class="all">
    <!-- <aside class="action">
      <div class="span">首页</div>
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      <div class="span">版权声明</div>
    </aside> -->
    <mainBox></mainBox>
    <div class="header">版权声明</div>
    <div class="body" v-html="content">

    </div>
        <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer";
import { fetch } from '../api/https';
import mainBox from "@/components/main.vue";

export default {
  data() {
    return {
      content:''
    };
  },
  created() {},
  mounted() {
    fetch("/getFootMenu").then((res) => {
        document.title = res.data[2].menu_seo_title;
var id = res.data[2].id;
        console.log(id)
        fetch('/menu/'+id).then((result) => {
        console.log(result);
        this.content = result.data.menu_content
      })
      });
  },
  methods: {},
  components: {
    Footer,mainBox
  },
};
</script>

<style scoped>
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 14px;
  box-sizing: border-box;
  margin-top: 49px;
  margin-bottom: 51px;
  height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #1a1a1a;
}
.action .span {
  font-family: MicrosoftYaHeiLight;
  float: left;
}
.action img {
  float: left;
  margin-top: 3px;
  height: 13px;
  width: 12px;
  margin-left: 6px;
  margin-right: 9px;
}
.privacy_detail {
  width: 1200px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  
}
.privacy_title {
  text-indent: 2em;
  font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 30px;
}
.privacy_details {
  text-indent: 2em;
  margin-bottom: 45px;
  font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 30px;
}
.header {
  max-width: 1400px;
  margin: 0 auto;
  height: 24px;
  background: url("../assets/zu74.png") no-repeat center;
  text-align: center;
  font-size: 20px;
  color: #b87646;
  line-height: 52px;
  font-weight: 400;
  padding-bottom: 31px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b87646;
}
.body {
  width: 1447px;
  margin: 0 auto;
  background-size: 100% 100%;
  padding-top: 118px;
  padding-left: 123px;
  padding: 118px 123px;
  box-sizing: border-box;
  background: url("../assets/link/tuceng4.png") no-repeat;
  font-size: 18px;
  line-height: 30px;
  box-sizing: border-box;
  background-size: 100% 100%;
  margin-bottom: 40px;
  padding-bottom: 137px;
}
.privacy_header {
  font-size: 18px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
line-height: 30px;
  text-indent: 2em;
  font-family: MicrosoftYaHei;
  width: 1200px;
  color: #333333;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-bottom: 30px;
}
.all {
  height: auto;
  width: 100%;
}
/* Footer{
} */
@media screen and (max-width: 1440px){
  .header{
    max-width: 1266px;
  }
  .body{
        width: 1300px;
    margin: 0 auto;
    height: auto;
    background-size: 100% 100%;
    padding-top: 116px;
    padding-left: 123px;
    box-sizing: border-box;
    background: url(/img/allBack.f980f998.png) no-repeat;
    background-size: 1300px 100%;
    padding-bottom: 109px;
    margin-bottom: 46px;
  }
  .privacy_header{
    width: 1055px;
  }
  .privacy_details{
    width: 1055px;

  }
}
</style>
